.main-header {
  height: 60px;
  width: 100%;
  background: #636363;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 30px;

  a {
    position: absolute;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffd740;
    font-size: medium;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 88px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}
