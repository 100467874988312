.gateway-button {
    border-radius: 5px;
    text-align: center;
    background-color: #FFF;
    border: 1px solid #DDD;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    margin: 5px 5px 5px 5px;

    &:hover{
        background-color: #EEE;
    }
}

.description {
    margin: 10px 10px 10px 10px;
    font-size: small;
    color: darken($color: #3f3f3f, $amount: 0);
}