.gift-icon{
    display: flex;
    align-items: center;

    img{
        width: 15px;
        height: 15px;
    }
}

.detail-gift{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 16px;
        height: 16px;
    }
}

.gift-icon span.description-data{
    position: absolute;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.6);
    left: 100px;
    padding: 10px;
    z-index: 100;
    text-align: center;
    
    p{
        font-size: 16px;
        margin: 10px;
    }
}

.detail-gift span.description-data{
    position: absolute;
    width: 350px;
    background-color: rgba(0, 0, 0, 0.7);
    top: 100px;
    padding: 10px;
    z-index: 100;
    text-align: center;
    
    p{
        font-size: 16px;
        margin: 10px;
    }
}