.payment-view {
    width: 100%;
}

.payment-section {
    margin-top: 0px;
    background: linear-gradient(#F3F3F3, #999999);
    
    .left-column, .right-column {
        min-height: 500px;
        font-size: 1.5em;
        display: flex;
    } 
    .right-column {
        background: none;
    }
    .left-column {
        background: linear-gradient(#999999,#F3F3F3);
    }
}

