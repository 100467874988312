.payment-view {
    width: 100%;
}

.left-column {
    height: 100%;
    top: 12px;
    min-height: 100px;
    font-size: 1.5em;
    display: flex;
    background: #F3F3F3;
    flex-direction: column;
}

.right-column {
    height: 100%;
    top: 12px;
    min-height: 100px;
    background: linear-gradient(#F3F3F3, #999999);
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #D11A2A;
    border-radius: 18px;
    border: 1px solid #cfcece;
}
.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.modal{
    background-color: #ffffff;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.modal-title{
    margin: 1rem;
    color: #212529;
    font: 2rem 'Montserrat', sans-serif
}

.modal-subtext{
    margin: 1rem;
    color: #716d6d;
    font: 1rem 'Montserrat', sans-serif;
}

.logo{
    width: 15rem;
    height: 15rem;
    object-fit: contain;
    margin-bottom: -3rem;
    margin-top: -2rem;
}

.copy-button{
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 80%;
    height: 2.8rem;
    border-radius: 0.5rem;
    background-color: #D11A2A;
    color: #ffffff;
    font: 1.5rem 'Montserrat', sans-serif;
    border: none;
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s ease;
}

.copy-button:hover{
    background-color: #f53041;
    color: #ffffff;
}