.pack-inner {
    height: 250px;
    width: 100%;
    position: relative;
    padding: 0px;
    background-color: #FFF;
    border: 1px solid #DDD;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &:hover{
        #description {
            opacity: 1;
        }
        #mid-panel,.img-wrapper img {
            opacity: 0.25;
        }
        #price,#full-price,#striked-price,#percentage {
            transform: translate(0px, -40px);
        }    
        #details #title {
            opacity: 0;
        } 
        #button {
            opacity: 1;
        }
    }

    #details{
        #title {
            white-space: nowrap;
        }
    }
}

.tag {
    position: absolute;
    padding: 4px 10px;

    color: white;
    background-color: #F15037;

    border-radius: 4px 0 4px 0;

    &.tag-right{
        right: 0;
    }

    h2 {
        font-size: 1.3em !important;
    }
}

#index {
    position: absolute;
    padding: 4px 8px;
}

#description {
    position: absolute;
    top: 40px;

    padding: 0px 20px;

    opacity: 0;
    transition: opacity 0.2s;

    p {
        font-size: 1.1rem;
        white-space: nowrap;
    }

    span {
        font-family: 'Roboto', sans-serif;
    }

    .details {
        font-size: 0.9rem;
        margin: 0;
    }

    p {
        font-size: 0.9rem;
    }
}

.pack-hot{
    background-color: rgb(253, 206, 135);
    #index {
        font-weight: 100;
        color: #FCBA57;
    }
    #mid-panel {
        background-color: #E39000;
    }
    #button {
        margin: 0 0 0 20px;
        width: 40% !important;
    }
}

.pack-exclusive{
    background-color: rgb(255, 130, 130);
    #index {
        font-weight: 100;
        color: #fc5757;
    }
    #mid-panel {
        background-color: #e30000;
    }
    #button {
        margin: 0 0 0 20px;
        width: 40% !important;
    }
}

.pack-normal
{
    #index {
        font-weight: 100;
        color: #CCCCCC;
    }
    #description {
        width: calc(100% - 40px);
    }
    #mid-panel {
        background-color: #DDD;
    }
    #button {
        margin: 0 0 0 20px;
        width: calc(100% - 40px) !important;
    }
}

.img-wrapper {
    position: absolute;
    overflow: hidden;
    height: 100%;
    right: 0;
    display: flex;
    justify-content: end;
    margin: 5px 5px 5px 5px;

    img {
        position: relative;
        width: 100%;
        max-width: 300px;
        min-width: 150px;
        height: auto;
    }
    div p {
        text-align: center;
        font-weight: 600;
        position: absolute;
        bottom: 10px;
        right: calc(25%);
        font-size: 0.8rem;
    }
}

#package-item-icon {
    width: 30px;
    height: 30px;
}

#mid-panel {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    border-radius: 4px;

    opacity: 0;
    transition: opacity 0.2s;
}

#details {
    position: absolute;
    bottom: 0;

    width: calc(100% - 40px);
    padding: 10px 20px;

    h5 {
        font-size: 1.1rem;
    }

    .details-price {
        display: flex;
        flex-direction: column;

        div {
            display: flex;
        }
    }
}

#price, #full-price, #striked-price, #percentage {
    display: inline-block;
}

#price {
    color: rgb(211, 0, 0);
    padding-right: 8px;
    font-weight: 900;
}

#full-price {
    color: rgb(211, 0, 0);
    text-decoration-style: solid;
    font-size: 0.8em;
}

#striked-price {
    text-decoration-line: line-through;
    text-decoration-style: solid;
    font-weight: 600;
    padding-right: 8px;
    font-size: 0.8rem;
}

#percentage {
    color: black;
    padding-right: 8px;
    font-weight: 600;
    font-size: 0.8rem;
}

#price, #full-price, #striked-price, #percentage {
    transition: transform 0.2s;
}

#title {
    transition: opacity 0.1s;
}

#button {
    position: absolute;
    bottom: 10px;

    height: 36px !important;

    color: #AB0000 !important;
    background: linear-gradient(#FFDD00, #FFB300) !important;

    border: #B8780A solid 1px !important;
    border-radius: 4px;

    opacity: 0;
    transition: opacity 0.2s;

    &:disabled {
        color: #777!important;
        background: #CCC !important;
        border: #AAA solid 0px !important;
    }
}

.gift-title {
    color: #AB0000 !important;
}

#unique-label {
    text-align: center;
    font-weight: 600;
    position: absolute;
    top: 10px;
    right: calc(25%);
    font-size: 0.8rem;
}

.only-mobile {
    display: none;
}

.gift-icon {
    white-space: nowrap;
    img {
        width: 15px;
        height: 15px;
    }
    span {
        margin: 0 0 0 3px;
        white-space: nowrap;
    }
}


@media screen and (max-width: 600px) {
    
    .pack-inner
    { 
        .mobile-img {
            margin-top: 10% !important;
            width: 70% !important;
            height: 70% !important;
            right: 0 !important;
            left: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        #button {
            opacity: 1;
        }

        #description {
            opacity: 1;
        }

        #mid-panel,.img-wrapper img {
            opacity: 0.25;
        }

        #price,#full-price,#striked-price,#percentage {
            transform: translate(0px, -40px);
        }

        #details #title {
            opacity: 0;
        }
    }

    #unique-label {
        right: 5px
    }

    #count-down {
        bottom: 5px;
        right: 10px;
    }

    .pack-hot #button {
        width: 50% !important;
    }
    .pack-exclusive #button {
        width: 50% !important;
    }

    .only-mobile {
        display: block;
    }

    #tag {
        padding: 4px 5px;

        h2 {
            font-size: 0.8rem !important;
        }
    }
}