.pack-info {
    text-align: center;
    .pack-img {
        height: 250px;
        width: 250px;
    }

    h2 {
        color: #810000;
        margin: 10px 0 10px 0;
    }

    .gifts-field {
        margin: 10px 10px 10px 10px;
        .detail-gift {
            font-size: medium;
            img {
                height: 15px;
                width: 15px;
            }
        }
    }
}

