.spinner-div {
    margin: 20px auto 0;
    padding: 20px;
    text-align: center;
}

.logo-div {
    margin: 20px auto 0;
    text-align: center;
}

.logo-div img {
    height: 120px;
}

.store-list {
    padding: 0 20px 0 20px;
}

.store-warning {
    border-radius: 5px;
    text-align: center;
    background-color: #FFF;
    border: 1px solid #DDD;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.historicButton {
    position: absolute;
}