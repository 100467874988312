.store-list {
  margin: 20px auto 0;
}

.stores {
  padding: 20px;
}

.store-item {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.2s;
  text-align: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  img {
    position: relative;
    width: 100%;
    max-width: 300px;
    min-width: 150px;
    height: auto;
  }
}

.store-item:hover {
  background: #eee;
}

h1 {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.maintence-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.maintence-alert img {
  height: 90%;
  width: 90%;
  object-fit: contain;
}
